import Cookies from "js-cookie";

const COOKIE_CONSENT_COOKIE_NAME = "cookie-consent";

export function getCookieConsentStateFromCookie() {
  const cookie = Cookies.get(COOKIE_CONSENT_COOKIE_NAME);

  if (cookie) {
    try {
      return JSON.parse(cookie);
    } catch (e) {
      console.error("Error during parsing cookie");
    }
  }

  return null;
}

export function setCookieConsentStateInCookie(state) {
  const cookieValue = state;

  Cookies.set(COOKIE_CONSENT_COOKIE_NAME, JSON.stringify(cookieValue), {
    expires: 365,
  });
}

export function generateCookieContentState(consent) {
  const filterAccepted = Object.values(consent).filter((elt) => elt === true);
  const accepted = filterAccepted.length > 0;

  return {
    status: accepted ? "accepted" : "declined",
    details: {
      adStorage: consent.adStorage ? "granted" : "denied",
      analyticsStorage: consent.analyticsStorage ? "granted" : "denied",
      functionalityStorage: consent.functionalityStorage ? "granted" : "denied",
      personalizationStorage: consent.personalizationStorage
        ? "granted"
        : "denied",
      securityStorage: consent.securityStorage ? "granted" : "denied",
    },
  };
}
