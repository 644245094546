import React, { useEffect } from "react";

import ReactModal from "react-modal";
import CloseIcon from "@bluevalet/react-icons/icon-close";
import { Link } from "react-router-dom";
import { Button, Switch } from "@bluevalet/common-ui";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import "./CookieConsentModal.scss";

export default function CookieConsentModal({
  isOpen,
  validateChoice,
  onRequestClose,
  consent,
}) {
  const [analyticsConsentState, setAnalyticsConsentState] = useState(
    consent?.details?.analyticsStorage === "granted"
  );
  const [pubConsentState, setPubConsentState] = useState(
    consent?.details?.adStorage === "granted"
  );

  const { t } = useTranslation("cookies");

  useEffect(() => {
    setAnalyticsConsentState(consent?.details?.analyticsStorage === "granted");
    setPubConsentState(consent?.details?.adStorage === "granted");
  }, [consent]);

  const modalStyle = {
    overlay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      maxHeight: "100%",
      zIndex: 10000,
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
    content: {
      position: "relative",
      top: "auto",
      right: "auto",
      left: "auto",
      bottom: "auto",
      width: 640,
      maxWidth: "100%",
      maxHeight: "100%",
      padding: "0",
      border: "none",
      background: "#ffffff",
      boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.07)",
      borderRadius: "2px",
      overflowY: "auto",
    },
  };

  const handleConsentValidation = () => {
    validateChoice({
      adStorage: pubConsentState,
      analyticsStorage: analyticsConsentState,
      functionalityStorage: false,
      personalizationStorage: false,
      securityStorage: false,
    });
    onRequestClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle}
      onRequestClose={onRequestClose}
    >
      <button
        className="CookieConsentModalCloseButton"
        onClick={onRequestClose}
      >
        <CloseIcon />
      </button>
      <div className="CookieConsentModalTitle">
        <p>{t("cookies.modal.title")}</p>
      </div>
      <div className="CookieConsentModalText">
        <p>
          <Trans t={t} i18nKey={"cookies.modal.content.title"} />
        </p>
        <p>
          <b>{t("cookies.modal.content.usage.title")}</b>
        </p>
        <ul>
          <li>{t("cookies.modal.content.usage.content.0")}</li>
          <li>{t("cookies.modal.content.usage.content.1")}</li>
        </ul>
        <p>
          <b>{t("cookies.modal.optionsTitle")}</b>
        </p>
      </div>
      <div className="CookieConsentModalSwitchs">
        <div>
          <label htmlFor="analyticsConsentState">
            {t("cookies.modal.options.0")}
          </label>
          <Switch
            id="analyticsConsentState"
            name="analyticsConsentState"
            onChange={() => {
              setAnalyticsConsentState(!analyticsConsentState);
            }}
            checked={analyticsConsentState}
          />
        </div>
        <div>
          <label htmlFor="pubConsentState">
            {t("cookies.modal.options.1")}
          </label>
          <Switch
            id="pubConsentState"
            name="pubConsentState"
            onChange={() => {
              setPubConsentState(!pubConsentState);
            }}
            checked={pubConsentState}
          />
        </div>
      </div>
      <div className="CookieConsentModalInfos">
        <p>
          {t("cookies.modal.checkLegalNoticeText.0")}
          <Link to="/legal-notices" target="_blank">
            {t("cookies.modal.checkLegalNoticeText.1")}
          </Link>
        </p>
      </div>
      <div className="CookieConsentModalFooter">
        <Button
          label={t("cookies.modal.buttonLabel")}
          primary
          onClick={handleConsentValidation}
        />
      </div>
    </ReactModal>
  );
}
